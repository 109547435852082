/* 🔹 Estilização Geral */
.container {
    display: flex;
    height: 100vh;
    background: #f4f4f4;
  }
  
  .dashboardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    border-left: 5px solid #1ca882;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
  }
  
  /* 🔹 Títulos das Pautas */
  .dashboardHeader h3 {
      font-size: 1.9rem;
      color: #1d715b;
      font-weight: bold;
      margin-bottom: 0px !important;
      padding-left: 0px !important;
      border-left: 0px !important;
      margin-top: 0px !important;
  }  
  
  .dashboardHeader img {
    height: 50px;
  }

  .dockMenu {
    position: fixed;
    bottom: 0; /* Gruda na base da tela */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(29, 113, 91, 0.9);
    padding: 10px 20px;
    border-radius: 20px 20px 0 0; /* Apenas bordas superiores arredondadas */
    display: flex;
    gap: 20px;
    z-index: 999;
    box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.3); /* Sombra para cima */
  }
  
  /* Botões do Dock */
  .dockBtn {
    background: none;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
    position: relative;
    transition: transform 0.2s ease;
  }
  
  .dockBtn:hover {
    transform: scale(1.2);
  }
  
  /* Tooltip (exibido acima) */
  .dockBtn::after {
    content: attr(title);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: #333;
    color: white;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 6px;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    transition: opacity 0.3s ease;
    margin-bottom: 6px;
  }
  
  .dockBtn:hover::after {
    opacity: 1;
  }
  
  .bottomButtons {
    margin-top: auto;
    width: 100%;
  }
  
  /* 🔹 Painel Principal */
  .painelContainer {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
  }
  
  /* 🔹 Header */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* 🔹 Status */
  .status {
    padding: 6px 12px;
    border-radius: 8px;
    font-weight: bold;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  /* 🔹 Cores do Status */
  .aberta {
    background: rgba(40, 167, 69, 0.15);
    color: #28a745;
  }
  
  .encerrada {
    background: rgba(220, 53, 69, 0.15);
    color: #dc3545;
  }
  
  /* 🔹 Informações Gerais */
  .sessaoInfo {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }
  
  .infoCard {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    text-align: center;
  }
  
  /* 🔹 Seções */
  .section {
    margin-top: 30px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .section h2 {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  /* 🔹 Lista */
  .section ul {
    margin-top: 10px;
  }
  
  .section li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  