/* 🔹 Estrutura Principal */
.content {
  padding: 20px;
}

.contentGerenciar {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}


.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  border-left: 5px solid #1ca882;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

/* 🔹 Títulos das Pautas */
.dashboardHeader h3 {
    font-size: 1.9rem;
    color: #1d715b;
    font-weight: bold;
    margin-bottom: 0px !important;
    padding-left: 0px !important;
    border-left: 0px !important;
    margin-top: 0px !important;
}  

.dashboardHeader img {
  height: 50px;
}

.dashboardContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.logo {
  height: 50px;
}

/* 🔹 Grid de opções */
.gridContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 40px;
}

.card {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  max-width: 180px;
  width: 100%;
}

.card:hover {
  transform: scale(1.01);
}

.configContainer {
  display: flex;
  height: 100vh;
  flex-direction: column;
  overflow-y: auto;
}

.dockMenu {
  position: fixed;
  bottom: 0; /* Gruda na base da tela */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(29, 113, 91, 0.9);
  padding: 10px 20px;
  border-radius: 20px 20px 0 0; /* Apenas bordas superiores arredondadas */
  display: flex;
  gap: 20px;
  z-index: 999;
  box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.3); /* Sombra para cima */
}

/* Botões do Dock */
.dockBtn {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease;
}

.dockBtn:hover {
  transform: scale(1.2);
}

/* Tooltip (exibido acima) */
.dockBtn::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 6px;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: opacity 0.3s ease;
  margin-bottom: 6px;
}

.dockBtn:hover::after {
  opacity: 1;
}

.icon {
  font-size: 7rem;
  color: #1d715b;
  margin-bottom: 10px;
}

h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

/* 🔹 Botões de ação */
.btnAction {
  display: flex;
  align-items: center;
  background-color: #1d715b;
  color: white;
  font-size: 16px;
  border: none;
  padding: 10px 15px;
  width: 100%;
  margin-top: 10px;
  cursor: pointer;
  transition: background 0.3s;
  border-radius: 8px;
}

.btnAction:hover {
  background-color: #1ca882;
}

.btnAction svg {
  margin-right: 8px;
}

/* 🔹 Conteúdo Principal */
.configContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  overflow: hidden;
}

/* 🔹 Header */
.configHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1d715b;
  color: white;
  padding: 15px 20px;
  border-radius: 5px;
}

.logo {
  height: 50px;
}

/* 🔹 Layout Principal */
.configMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  text-align: center;
}

/* 🔹 Botões de Configuração */
.configSections {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.configButton {
  padding: 12px 18px;
  font-size: 16px;
  background-color: #0a4b3a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.configButton:hover {
  background-color: #0a4b3a;
}

.bottomButtons {
  display: flex;
  flex-direction: column;
  margin-top: auto; /* Move os botões para a base */
}

.bottomButtons button {
  transform: scale(1.1);
}