
/* 🔹 Fundo escurecido e desfocado */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  backdrop-filter: blur(6px);
  animation: fadeIn 0.3s ease-in-out;
  padding: 20px;
  overflow-y: auto;
}

/* 🔹 Estilização do Modal */
.modal {
  display: flex;
  flex-wrap: wrap !important;
  flex-direction: none !important;
  justify-content: space-between;
  align-items: left;
  gap: 12px;
  background: #fff;
  width: 100%;
  max-width: 720px;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.3);
  animation: slideDown 0.3s ease-in-out;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
}

/* 🔹 Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(0);
  }
}

/* 🔹 Título do Modal */
.modalTitle {
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: #1d715b;
  margin-bottom: 25px;
  border-bottom: 3px solid #1d715b;
  padding-bottom: 12px;
}

.modalTitle svg {
  margin-right: 12px;
  color: #1ca882;
}

/* 🔹 Botão de Fechar */
.modalClose {
  position: absolute;
  top: 12px;
  right: 15px;
  background: none;
  border: none;
  font-size: 22px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease-in-out;
}

.modalClose:hover {
  color: #1ca882;
}

/* 🔹 Layout das linhas */
.row {
  display: flex;
  justify-content: space-between;
  gap: 18px;
  flex-wrap: wrap;
}

.row .inputGroup {
  flex: 1;
  min-width: 250px;
}

/* 🔹 Duas colunas para campos menores */
.row-two {
  display: flex;
  gap: 18px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row-two .inputGroup {
  flex: 1;
  min-width: 150px;
}

/* 🔹 Agrupamento dos Inputs */
.inputGroup {
  display: flex;
  flex-direction: column;
}

/* 🔹 Labels */
label {
  font-size: 1.2rem;
  font-weight: bold;
  color: #11503f;
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

/* 🔹 Ícones nos Labels */
label svg {
  margin-right: 10px;
  color: #1d715b;
}

/* 🔹 Títulos das Seções */
h2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #1d715b;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 2px solid #1d715b;
}

/* 🔹 Títulos das Pautas */
h3 {
  font-size: 1.2rem;
  color: #1d715b;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 10px;
  border-left: 4px solid #1ca882;
}

/* 🔹 Botão de Salvar */
button[type="submit"] {
  background: #1d715b;
  color: #fff;
}

button[type="submit"]:hover {
  background: #11503f;
}

/* 🔹 Botão de Cancelar */
button.cancel {
  background: #ff4d4d;
  color: white;
}

button.cancel:hover {
  background: #b30000;
}

/* 🔹 Responsividade */
@media (max-width: 600px) {
  .modal {
    width: 95%;
    max-width: 420px;
    padding: 22px;
  }
  
  .row {
    flex-direction: column;
  }

  .row-two {
    flex-direction: column;
  }
}
