/* 🔹 Estrutura Principal */
.painelContainer {
    padding: 20px;
    background-color: #f8f9fa;
  }

.container {
    display: flex;
    gap: 20px;
    padding: 20px;
  }

  .leftPanel, .rightPanel {
    flex: 1;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 1.8rem;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .mesaDiretora {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
  
  .card {
    background: white;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .icon {
    font-size: 2.5rem;
    color: #1d715b;
    margin-bottom: 10px;
  }
  
  .bottomButtons {
    display: flex;
    flex-direction: column;
    margin-top: auto; /* Move os botões para a base */
  }

/* 🔹 Media Query para telas menores */
@media screen and (max-width: 767px) {
    /* 🔹 Estrutura Principal */
.painelContainer {
  margin-left: 0px;
  padding: 20px;
  background-color: #f8f9fa;
}

.container {
  display: flex;
  gap: 20px;
  padding: 20px;
  flex-direction: column;
  align-content: space-around;
  align-items: center;
}
}