

/* 🔹 Container do login */
.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  text-align: center;
  width: 100%;
  height: 100vh;

}

.loginPlay {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  gap: 15px;

}

.formLogin {
  display: flex;
  flex-direction: column;
}

.logoContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 30px;
  background: #fff;
  border-radius: 8px;
  border-left: 5px solid #1ca882;
  padding: 30px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

h4 {
  font-size: 1.2rem;
    color: #1d715b;
    font-weight: bold;
    margin-bottom: 10px;
}

/* 🔹 Estilização do logotipo */
.logo {
  max-width: 280px;
  margin-bottom: 1.2rem;
}

  /* Responsivo */  
  @media (max-width: 768px) {
    .loginContainer {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      background: #fff;
      text-align: center;
      width: auto;
    }
    
    .loginPlay {
      display: flex      ;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      align-content: center;
      justify-content: center;
    }
    
    .formLogin {
      display: flex;
      flex-direction: column;
    }
    
    .logoContainer {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      gap: 0 !important;
      background: #fff;
      border-radius: 8px;
      border-left: 5px solid #1ca882;
      padding: 30px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      transition: background 0.3s ease;
    }
  }

/* 🔹 Estilização do título */
.loginContainer h2 {
  font-size: 1.8rem;
  color: #002c5b;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

/* 🔹 Estilização dos campos de entrada */
.input {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 1rem;
  background-color: #fdfdfd;
}

/* 🔹 Botão de login */
.btnEntrar {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.btnEntrar:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
}
