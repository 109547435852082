/* 🔹 Estrutura Principal */
.painelContainer {
  padding: 20px;
  background-color: #f8f9fa;
}

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  border-left: 5px solid #1ca882;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

/* 🔹 Títulos das Pautas */
.dashboardHeader h3 {
    font-size: 1.9rem;
    color: #1d715b;
    font-weight: bold;
    margin-bottom: 0px !important;
    padding-left: 0px !important;
    border-left: 0px !important;
    margin-top: 0px !important;
}  

.dashboardHeader img {
  height: 50px;
}

/* 🔹 Sidebar */
.sidebar {
  width: 80px;
  height: 100vh;
  background-color: #1d715b;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
}

.sidebarBtn {
  background: none;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 20px;
  width: 100%;
}

.sidebarBtn:hover {
  background-color: #1ca882;
  width: 100%;
}

/* Submenu da Sidebar */
.sidebarItem {
  margin-top: 20px;
}

.submenu {
  padding-left: 20px;
}

.chevron {
  margin-left: 10px;
}

/* 🔹 Área Principal */
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}

.section {
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 15px;
}

.header {
  background: #1d715b;
  color: white;
  padding: 20px;
  margin-bottom: 20px;
}

.usuariosContainer h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

/* 🔹 Container de Usuários */
.usuarioGrupo {
  margin-bottom: 30px;
}

.usuarioGrupo h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.usuarioGrupo ul {
  list-style-type: none;
  padding-left: 0;
}

.usuarioGrupo li {
  padding: 10px;
  background: #f9f9f9;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

/* 🔹 Modal */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  backdrop-filter: blur(6px);
  animation: fadeIn 0.3s ease-in-out;
}

.modal {
  background: #fff;
  width: 100%;
  max-width: 720px;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.3);
  animation: slideDown 0.3s ease-in-out;
  position: relative;
}

.modalClose {
  position: absolute;
  top: 12px;
  right: 15px;
  background: none;
  border: none;
  font-size: 22px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease-in-out;
}

.modalClose:hover {
  color: #1ca882;
}

/* 🔹 Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-30px);
  }
  to {
    transform: translateY(0);
  }
}

/* 🔹 Responsividade */
@media (max-width: 768px) {
  .painelGrid {
    grid-template-columns: 1fr;
  }
}

/* 🔹 Estrutura de Layout para o Modal */
.modalConteudo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btnAcao {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 30px;
  height: 30px;
}

.iconeAcao {
  font-size: 1.4rem;
}

/* Botões do Modal */
.btnExibir {
  background-color: #4caf50;
  color: #fff;
}

.btnExibir:hover {
  background-color: #0a4b3a;
}

.btnOcultar {
  background-color: #f44336;
  color: #fff;
}

.btnOcultar:hover {
  background-color: #0a4b3a;
}

.btnConcluir {
  background-color: #ff9800;
  color: #fff;
}

.btnConcluir:hover {
  background-color: #0a4b3a;
}

/* Botões de Votação */
.btnVotacao {
  background-color: #1976d2;
  color: #fff;
}

.btnVotacao:hover {
  background-color: #0a4b3a;
}

/* 🔹 Painel de Controle (Itens de Painel) */
.painelGrid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 20px;
}

.painelItem {
  font-size: 0.7rem;
  text-transform: uppercase;
}

.painelItem p {
  background-color: #4caf50;
  padding: 5px 5px;
  border-radius: 2px;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
}

/* 🔹 Blocos de Controle */
.ritoSessao {
  display: grid;
  overflow-y: auto;
  padding: 10px;
  background-color: #1d715b;
  border-radius: 5px;
}

h3 {
  color: white;
}

.ritosBlocos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.ritoBloco {
  padding: 12px;
  border-radius: 5px;
}

.ritoBloco h4 {
  font-size: 1rem;
  margin-bottom: 8px;
  color: #fff;
}

/* 🔹 Listas dentro dos Blocos */
.ritoBloco ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ritoBloco li {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: grab;
  transition: transform 0.2s;
  border: none !important;
}

.ritoBloco li:hover {
  transform: scale(1.01);
}

/* 🔹 Ordem do Dia */
.ordemDoDia {
  flex: 1;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.itemPauta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.itemPauta button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.vereadorCard {
  display: flex;
  align-items: center;
  gap: 10px;
}

.vereadorFoto {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.ordemDoDia h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.ordemDoDia ul {
  list-style: none;
  padding: 0;
}

.ordemDoDia li {
  padding: 12px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none !important;
}

.ordemDoDia li:hover {
  background-color: #f0f8ff;
  transform: scale(1.01);
}

.ordemDoDia button {
  background: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  transition: 0.3s;
}

.ordemDoDia button:hover {
  background: #0a4b3a;
}

.bottomButtons {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}

.botaoPause:hover,
.botaoPlay:hover,
.botaoStop:hover {
  background-color: #0a4b3a;
}

/* 🔹 Pauta Grupo */
.pautaGrupo {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

.pautaGrupo:hover {
  background: #e9f7f4;
}

.pautaGrupo h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #1d715b;
}

.pautaGrupo ul {
  list-style: none;
  padding: 0;
}

.pautaGrupo li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.pautaGrupo li button {
  background: none;
  border: none;
  color: #1ca882;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.pautaGrupo li svg {
  margin-right: 8px;
}


/* 🔹 Estilização da Tabela */
.pautaTabela {
  width: 100%;
  border-collapse: collapse;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

.pautaTabela th, .pautaTabela td {
  padding: 12px 15px;
  text-align: left;
}

.pautaTabela th {
  font-size: 16px;
  font-weight: bold;
  color: #FFF;
  background-color: #1d715b;
}

.pautaTabela td {
  vertical-align: middle;
  font-size: 14px;
}

tr {
  color: #11503f;
  transition: transform 0.2s;
}

tr:hover {
transform: scale(1.01);
}

/* 🔹 Manter ícones alinhados ao texto */
.pautaTabela td svg {
  margin-right: 8px;
  color: #1ca882;
}

/* 🔹 Alinhamento das Ações */
.acoes {
  display: flex;
  gap: 8px;
}

.acoes button {
  background: none;
  border: none;
  color: #1ca882;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}
