/* 🔹 Header */
.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  border-left: 5px solid #1ca882;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

/* 🔹 Títulos das Pautas */
.dashboardHeader h3 {
    font-size: 1.9rem;
    color: #1d715b;
    font-weight: bold;
    margin-bottom: 0px !important;
    padding-left: 0px !important;
    border-left: 0px !important;
    margin-top: 0px !important;
}  

.dashboardHeader img {
  height: 50px;
}

/* 🔹 Estrutura Principal */
.content {
  background-color: #f8f9fa;
  height: 100vh;
  padding: 20px;
}

.contentGerenciar {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: auto;
}

.dashboardContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
}

.logo {
  height: 50px;
}

.dockMenu {
  position: fixed;
  bottom: 0; /* Gruda na base da tela */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(29, 113, 91, 0.9);
  padding: 10px 20px;
  border-radius: 20px 20px 0 0; /* Apenas bordas superiores arredondadas */
  display: flex;
  gap: 20px;
  z-index: 999;
  box-shadow: 0 -2px 12px rgba(0, 0, 0, 0.3); /* Sombra para cima */
}

/* Botões do Dock */
.dockBtn {
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease;
}

.dockBtn:hover {
  transform: scale(1.2);
}

/* Tooltip (exibido acima) */
.dockBtn::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 6px;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: opacity 0.3s ease;
  margin-bottom: 6px;
}

.dockBtn:hover::after {
  opacity: 1;
}

/* Estilo do Grupo de Pautas */
.painelSessao {
  background: #e9f7f4;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}



/* 🔹 Itens arrastáveis */
ul {
    list-style: none;
    padding: 0;
    font-size: 0.5rem;
  }
  
  li {
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background 0.3s;
    border-radius: 2px !important;
    font-size: 0.8rem;
    border: none !important;
  }
  
  li:hover {
    background-color: #d8f3e6 !important;
      transform: scale(1.01);  
  }

  strong {
    font-weight: normal;
  }

/* ESTILOS DOS MODAIS */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    backdrop-filter: blur(6px);
    animation: fadeIn 0.3s ease-in-out;
    height: 100vh;
  }
  
  .modalConfigSessao {
    width: 100%;
    height: fit-content;
    background: #fff;
    padding: 20px;
    animation: slideDown 0.3s ease-in-out;
    position: relative;  
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
  }
  
  .modal {
    width: 100%;
    background: #fff;
    padding: 20px;
    animation: slideDown 0.3s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow-y: auto;
  }
  
  .forms {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  
  .formResumo {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* 🔹 Animações */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-30px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* 🔹 Título do Modal */
  .modalTitle {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
    color: #1d715b;
    border-bottom: 3px solid #1d715b;
    padding-bottom: 8px;
    margin-bottom: 20px;
  }
  
  .modalTitle svg {
    margin-right: 12px;
    color: #1ca882;
  }
  
  /* 🔹 Botão de Fechar */
  .modalClose {
    position: absolute;
    top: 12px;
    right: 15px;
    background: none;
    border: none;
    font-size: 22px;
    cursor: pointer;
    color: #666;
    transition: color 0.3s ease-in-out;
    padding: 20px;
  }
  
  .modalClose:hover {
    color: #1ca882;
  }
  
  /* 🔹 Formulário */
  form {
    font-size: 1.5rem;
    gap: 12px;
  }
  
  /* 🔹 Duas colunas para campos menores */
  .row-two {
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .row-two .inputGroup {
    flex: 1;
    min-width: 150px;
  }
  
  /* 🔹 Agrupamento dos Inputs */
  .inputGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .inputGroup li {
      align-items: center;
      border: none !important;
      border-radius: 2px !important;
      cursor: pointer;
      display: flex;
      font-size: 1.8rem;
      justify-content: space-between;
      padding: 5px 10px;
      transition: background .3s;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  
  /* 🔻 Conteúdo do formulário, torna rolável */
  .formPautaAdd {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px; /* espaçamento entre os grupos */
    padding-right: 8px;
    color: #0a4b3a;
  }
  
  /* 🔻 Conteúdo do formulário, torna rolável */
  .formPauta {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px; /* espaçamento entre os grupos */
    padding-right: 8px; /* espaço pro scrollbar */
    max-height: 400px;
    overflow-y: auto;
    color: #0a4b3a;
  }
  
  /* 🔹 Estilização da barra de rolagem do formulário */
  .formPauta::-webkit-scrollbar {
    width: 10px;
  }
  
  .formPauta::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  
  .formPauta::-webkit-scrollbar-thumb {
    background: #1ca882;
    border-radius: 10px;
  }
  
  .formPauta::-webkit-scrollbar-thumb:hover {
    background: #1ca882;
  }
  
  .formPauta span {
    font-size: 0.9rem;
  }
  
  /* 🔹 Labels */
  label {
    font-size: 0.85rem;
    font-weight: bold;
    color: #11503f;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }
  
  /* 🔹 Ícones nos Labels */
  label svg {
    margin-right: 10px;
    color: #1d715b;
  }

  /* 🔹 Melhorando o Upload de Arquivo */
  .fileInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f0f0f0;
    padding: 12px;
    border-radius: 3px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .fileInput:hover {
    background: #e0e0e0;
  }
  
  /* 🔹 Melhorando Textarea */
  textarea {
    height: fit-content;
    margin-top: 0 !important;
    padding: 12px;
    border-radius: 3px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s;
    resize: vertical;
    font-family: "Arial", sans-serif;
    line-height: 1.5;
    background: #f8f8f8;
  }
  
  textarea:focus {
    border-color: #1ca882;
    background: #fff;
  }
  
  /* 🔻 Rodapé fixado na base da modal */
  .modalActions, .modalActionsPresensa {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    padding-top: 12px;
    margin-top: auto;
  }
  
  .modalActions button, .modalActionsPresensa button {
    min-width: 32%;
    padding: 14px 20px;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
  
  .modalActions button svg {
    font-size: 16px;
  }
  
  button {
    background: none;
    border: none;
    color: #1ca882;
    cursor: pointer;
    font-size: 1.9rem;
    transition: color 0.3s ease;
    padding: 6px 12px;
    border-radius: 5px;
  }

  button:hover {
    transform: scale(1.05);
  }
  
  .attachmentLink {
    background: #e9f7f4;
    padding: 10px 14px;
    border-radius: 6px;
    font-weight: bold;
    color: #1d715b;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    transition: background 0.2s ease-in-out;
  }
  
  .attachmentLink:hover {
    background: #d4efe9;
  }
  
  /* 🔹 Pauta Grupo */
.pautaGrupo {
  background: #fff;
  border-radius: 8px;
  border-left: 5px solid #1ca882;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
  max-height: 400px;
  overflow-y: auto;
}

/* 🔹 Estilização da barra de rolagem */
.pautaGrupo::-webkit-scrollbar {
  width: 10px;
}

.pautaGrupo::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.pautaGrupo::-webkit-scrollbar-thumb {
  background: #1ca882;
  border-radius: 10px;
}

.pautaGrupo::-webkit-scrollbar-thumb:hover {
  background: #1ca882;
}

.pautaGrupo li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pautaGrupo li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

  .pautaGrupo h3 {
    font-size: 18px;
    color: #11503f;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  /* Cores dos Botões */
  .btnSim {
    background: #28a745;
    color: white;
  }
  
  .btnAvancar {
    background: #28a745;
    color: white;
  }
  
  .btnSalvar {
    background: #28a745;
    color: white;
  }
  
  .btnVoltar {
    background: #11503f;
    color: white;
  }
  
  .btnNao {
    background: #dc3545;
    color: white;
  }
  
  .btnCancelar {
    background: #dc3545;
    color: white;
  }
  
  .btnAbstencao {
    background: #ffc107;
    color: #333;
  }
  
  /* 🔹 Títulos das Seções */
  h1 {
    font-size: 1rem;
    font-weight: bold;
    color: #1d715b;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid #1d715b;
  }
  
  /* 🔹 Títulos das Seções */
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1d715b;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid #1d715b;
  }
  
  /* 🔹 Títulos das Pautas */
  h3 {
    font-size: 1.2rem;
    color: #1d715b;
    font-weight: bold;
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 4px solid #1ca882;
  }
  
  .resumoSessao {
    display: flex;
    flex-wrap: wrap !important;
    flex-direction: none !important;
    justify-content: space-between;
    align-items: left;
    gap: 12px;    
  }
  
  .resumoSessao p {
    background: #fff;
    border-radius: 5px;
      padding: 10px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      transition: background 0.3s ease;
  }
  
  .uploadButton {
    display: flex;
    align-items: center;
    background-color: #007bff;
    color: white;
    padding: 8px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  }
  
  .uploadButton:hover {
    background-color: #0056b3;
  }
  
  .uploadButton .icon {
    margin-right: 8px;
    font-size: 16px;
  }
  
  .modalSection {
    padding: 12px;
    background: #f9f9f9;
    border-radius: 8px;
  }
  
  .modalCreateSection {
    padding: 12px;
    background: #f9f9f9;
    border-radius: 8px;
  }
  
  .modalSection h3 {
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
  }
  
  .textBlock {
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 15px;
    line-height: 1.6;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-height: 250px;
    overflow-y: auto;
    color: #333;
  }
  
  .status {
    font-weight: bold;
    color: #007bff;
  }
  
  .downloadLink {
    display: flex;
    align-items: center;
    background: #007bff;
    color: white;
    padding: 10px;
    border-radius: 6px;
    text-decoration: none;
    transition: background 0.3s;
  }
  
  .downloadLink:hover {
    background: #0056b3;
  }
  
  .downloadLink svg {
    margin-right: 8px;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContent {
    max-width: 500px;
    gap: 12px;
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.3);
    animation: slideDown 0.3s ease-in-out;
    position: relative;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .btn-confirm {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .btn-cancel {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .autorTag {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    justify-items: center;
  }
  
  
  @media (max-width: 768px) {
    .inputGroup {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  
    .row {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      width: 100%;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
  
  /* Container do Seletor */
  .seletorContainer {
    display: flex;
    gap: 10px;
    padding: 10px;
    border-radius: 12px;
    color: #0a4b3a;
    flex-wrap: wrap; /* Para garantir que o layout se ajuste em telas pequenas */
  }
  
  /* Cards do Seletor */
  .seletorCard {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 8px 12px;
    border-radius: 8px;
    transition: background-color 0.2s ease, transform 0.2s ease;
    background: #e9f7f4;
    border: 2px solid transparent;
    user-select: none; /* Para evitar que o texto seja selecionado */
  }
  
  /* Hover nos cards */
  .seletorCard:hover {
    transform: scale(1.05);
    background-color: #d8f3e6;
  }
  
  /* Estilizando o card ativo */
  .seletorCard.ativo {
    border: 2px solid #0d6efd;
    background: rgba(13, 110, 253, 0.1);
  }
  
  .customFileInput {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 0.3rem;
  }
  
  .customFileInput input[type="file"] {
    display: none;
  }
  
  .uploadBtn {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s ease-in-out;
  }
  
  .uploadBtn:hover {
    background-color: #0056b3;
  }
  
  .customFileInput span {
    font-size: 0.9rem;
    color: #333;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

.pageTitle {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

/* 🔹 Grid de opções */
.gridContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.card {
  background: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  max-width: 180px;
  width: 100%;
}

.card:hover {
  transform: scale(1.01);
}

.usuariosContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}

.usuarioGrupo {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  transition: background 0.3s ease;
}

.usuarioGrupo:hover {
  background: #e9f7f4;
}

.usuarioGrupo h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #1d715b;
}

.usuarioGrupo ul {
  list-style: none;
  padding: 0;
}

.usuarioGrupo li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.usuarioGrupo li button {
  background: none;
  border: none;
  color: #1ca882;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.usuarioGrupo li button:hover {
  color: #11503f;
}

.usuarioGrupo li svg {
  margin-right: 8px;
}

.bottomButtons {
  display: flex;
  flex-direction: column;
  margin-top: auto; /* Move os botões para a base */
}

.bottomButtons button {
  transform: scale(1.1);
}

/* 🔹 Responsividade */
@media (max-width: 768px) {
  .dashboardMain {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 20px;
    overflow: hidden;
    width: 100%;
  }
  .dashboardContainer {
    margin-left: 0px;
  }

  .materiasExibidas {
    width: 100%;
  }

}

.vereadorDestaque {
  background-color: #000;
  border: 4px solid #FFD700;
  border-radius: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
}

.vereadorDestaque img {
  max-width: 180px;
  border-radius: 12px;
  border: 3px solid white;
  margin-bottom: 20px;
}

.vereadorDestaque h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.vereadorDestaque .partidoImg {
  width: 80px;
  margin-top: 10px;
}

.vereadorDestaque .timerDisplay {
  font-size: 3rem;
  background: #222;
  border-radius: 12px;
  padding: 15px 30px;
  margin-top: 20px;
  border: 2px solid #ff4444;
  color: #ff4444;
}


/* 🔹 Estrutura Principal */
.painelContainer {
    display: flex;
  }
  
  /* 🔹 Cabeçalho do Painel */
  .topoControle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1d715b;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  
  .topoControle h2 {
    font-size: 1.7rem;
    font-weight: bold;
    color: #0a4b3a;
  }
  
  .btnAcao,
  .btnEncerrar,
  .btnVotar {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.3s;
    margin-left: 10px;
    width: 110px;
  }
  
  .btnAcao:hover {
    background-color: #0a4b3a;
  }
  
  .btnEncerrar {
    background-color: #dc3545;
    width: 110px;
  }
  
  .btnEncerrar:hover {
    background-color: #0a4b3a;
  }
  
  /* 🔹 Grid Principal */
  .painelGrid {
    display: grid;
    grid-template-columns: 3fr 5fr;
    align-items: start;
    gap: 20px;
  }
  
  /* 🔹 Rito da Sessão - Distribui os blocos em 2 colunas */
  .painelExibicao {
    display: flex
    ;
        gap: 10px;
        border-radius: 5px;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: stretch;
  }
  
  .painelExibicao li {
    padding: 10px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: grab;
    transition: transform 0.2s;
    border: none !important;
  }
  
  .painelExibicao li strong {
    text-align: left;
  }
  
  .painelExibicao li:hover {
    transform: scale(1.01);
  }
  
  /* 🔹 Rito da Sessão - Distribui os blocos em 2 colunas */
  .ritoSessao {
    display: grid;
    overflow-y: auto; /* 🔥 Adiciona rolagem apenas se necessário */
    padding: 10px;
    background-color: #1d715b;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .TituloPadrao {
    text-align: left;
  }
  
  /* 🔹 Grid para os Blocos dentro do Rito da Sessão */
  .ritosBlocos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  
  /* 🔹 Bloco Individual */
  .ritoBloco {
    padding: 12px;
    border-radius: 5px;
  }
  
  .ritoBloco h4 {
    font-size: 1rem;
    margin-bottom: 8px;
    color: #fff;
  }
  
  /* 🔹 Lista dentro dos Blocos */
  .ritoBloco ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .ritoBloco li {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    cursor: grab;
    transition: transform 0.2s;
    border: none !important;
  }
  
  .ritoBloco li:hover {
    transform: scale(1.01);
  }
  
  
  /* 🔹 Seções do Painel */
  .ordemDoDia {
    flex: 1;
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  
  .itemPauta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
  }
  
  .itemPauta button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
  }
  
  .vereadorCard {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .vereadorFoto {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  
  .ordemDoDia h3 {
    color: #333;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  /* 🔹 Lista de Itens */
  .ordemDoDia ul {
    list-style: none;
    padding: 0;
  }
  
  .ordemDoDia li {
    padding: 12px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none !important;
  }
  
  .ordemDoDia li:hover {
    background-color: #f0f8ff;
      transform: scale(1.01);
  }
  
  /* 🔹 Botões dentro da lista */
  .ordemDoDia button {
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    transition: 0.3s;
  }
  
  /* 🔹 Responsividade */
  @media (max-width: 768px) {
    .painelGrid {
      grid-template-columns: 1fr;
    }
  
  /* 🔹 Área Principal */
  .painelConteudo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-left: 0;
  }
  
  .topoControle {
    display: flex  ;
        justify-content: space-between;
        color: #1d715b;
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 15px;
        text-transform: uppercase;
        flex-direction: column;
        align-content: space-between;
        align-items: center;
        text-align: center;
  }

  .section {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 15px;
  }
  }
  
  /* Estilos para o conteúdo do modal */
  .modalConteudo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Garantindo que os botões fiquem alinhados horizontalmente */
  .acoes {
    display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        
  } 

  .dashboardButton {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .acoesDetalhes {
    display: flex;
    flex: 1;
    gap: 10px;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
  }

  .acoesDetalhes button, .dashboardButton button, .acoesDetalhes a {
    background: #1ca882;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 0.9rem;
    padding: 12px 12px;
    transition: color .3s ease;
  }
  
  .acoesDetalhes > * {
    flex: 1;
    max-width: 33.33%;
    text-align: center;
  }
  
  /* Estilização dos botões de ação */
  .btnAcao {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 2.9rem;
    height: 2.9rem;
  }
  
  .iconeAcao {
    font-size: 1.2rem; /* Ajusta o tamanho do ícone */
  }
  
  .btnDetalhes {
    background-color: #1ca882;
    color: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 5px;
  }

  .btnDetalhes:hover {
    background-color: #626161;
  }

  .btnExibir {
    background-color: #1ca882;
    color: #fff;
  }

  .btnExibir:hover {
    background-color: #626161;
  }
  
  /* Botão de Ocultar */
  .btnEsconder {
    background-color: #0a4b3a;
    color: #fff;
  }
  
  .btnEsconder:hover {
    background-color: #626161;
  }
  
  .btnConcluir {
    background-color: #ff9800;
    color: #fff;
  }
  
  .btnConcluir:hover {
    background-color: #0a4b3a;
  }

  .btnRemover {
    background-color: #ec6e44;
    color: #fff;
  }
  
  .btnRemover:hover {
    background-color: #626161;
  }

  /* Tooltip (exibido acima) */
  .btnDetalhes::after,
  .btnExibir::after,
  .btnEsconder::after,
  .btnRemover::after,
  .btnConcluir::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 6px;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: opacity 0.3s ease;
  margin-bottom: 6px;
}

.btnDetalhes:hover::after,
.btnExibir:hover::after,
.btnOcultar:hover::after,
.btnEsconder:hover::after,
.btnRemover:hover::after,
.btnConcluir:hover::after
 {
  opacity: 1;
}
  
  /* Botão de Votação */
  .btnVotacao {
    background-color: #1976d2;
    color: #fff;
  }
  
  .btnVotacao:hover {
    background-color: #0a4b3a;
  }
  
  .iconTitle {
    margin-right: 8px; /* Espaço entre o ícone e o texto */
    font-size: 20px; /* Tamanho adequado */
    vertical-align: middle;
    color: #fff; /* Azul para dar destaque */
  }
  
  .botoesControle {
    display: flex;
    gap: 10px;
    border: none;
    font-size: 1.8rem;
  }
  
  .botaoPlay {
    background: #4caf50;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1.3rem;
  }
  
  .botaoPause {
    background: #ff9800;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1.3rem;
  }
  
  .botaoStop {
    background: #f44336;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    font-size: 1.3rem;
  }
  
  .bottomButtons {
    display: flex;
    flex-direction: column;
    margin-top: auto; /* Move os botões para a base */
  }
  
  .botaoPause:hover, .botaoPlay:hover, .botaoStop:hover { 
    background-color: #0a4b3a;
  }
  
  
  .painelItem {
    font-size: 0.9rem;
    text-transform: uppercase;
    color: #0a4b3a;
    
  }
  
  .painelItem  p {
    background-color: #1ca882;
    padding: 8px 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  .section {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    color: #0a4b3a;
  }s  
  
  /* 🔹 Container Principal */
  .votacaoContainer {
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    align-content: space-around;
    align-items: stretch;
  }
  
  /* 🔹 Título */
  .votacaoContainer h3 {
    font-size: 1.2rem;
    color: #1d715b;
    margin-bottom: 15px;
    text-align: left;
  }
  
  /* Container dos Filtros */
  .filtrosContainer {
    display: flex;
        justify-content: flex-start;
        gap: 15px;
        border-radius: 12px;
        color: #0a4b3a;
  }
  
  /* Cards do Filtro */
  .filtroCard {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 1rem;
    padding: 8px 12px;
    border-radius: 8px;
    transition: background-color 0.2s ease, transform 0.2s ease;
    background: #e9f7f4;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
      
  }

    .filtroVotacao {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      font-size: 1rem;
      padding: 8px 12px;
      border-radius: 8px;
      transition: background-color 0.2s ease, transform 0.2s ease;
      background: #1ca882;
      box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1); /* Sombra para cima */
    }

    .filtroVotacao strong {
      color: white;
      font-size: 1.5rem;
      font-weight: bold;
    }
  
  .filtroCard:hover {
    transform: scale(1.01);
  }
  
  /* Ícones do Filtro */
  .filtroIcon {
    font-size: 1.2rem;
  }
  
  /* Estilizando cards ativos */
  .filtroCard.ativo {
    border: 2px solid #0d6efd;
    background: rgba(13, 110, 253, 0.1);
  }

  .btnAdicionar {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
    transition: transform 0.2s ease;
  }
  
  .btnAdicionar:hover {
    transform: scale(1.01);
  }
  
  /* Responsivo */  
  @media (max-width: 768px) {
    .filtrosContainer {
      justify-content: center;
    }
  
    .filtroCard {
      font-size: 1.2rem;
    }
  }

  /* 🔹 Filtro */
.filterContainer {
  margin: 20px 0;
  display: flex;
  align-items: center;
}

.filterContainer label {
  font-size: 16px;
  margin-right: 10px;
}

.filterSelect {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

/* 🔹 Grid de Pautas */
.pautasGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 20px;
}

/* 🔹 Card de Pauta */
.pautaCard {
  background: #e9f7f4;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: row;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  border-left: 6px solid #1ca882;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

/* 🔹 Efeito hover */
.pautaCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* 🔹 Cabeçalho */
.pautaInfo {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  font-weight: bold;
}

/* Ícone */
.pautaInfo .icon {
  font-size: 22px;
  color: #1d715b;
}

/* 🔹 Status */
.pautaStatus {
  font-size: 14px;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 15px;
  display: inline-block;
  text-align: center;
  width: fit-content;
}

/* 🔹 Cores por status */
.pautaCard[data-status="novo"] {
  border-left-color: #28a745;
}
.pautaCard[data-status="aprovado"] {
  border-left-color: #28a745;
}
.pautaCard[data-status="rejeitado"] {
  border-left-color: #dc3545;
}
.pautaCard[data-status="aguardando votação"] {
  border-left-color: #007bff;
}
.pautaCard[data-status="encaminhado"] {
  border-left-color: #ffc107;
}

/* 🔹 Cor de fundo do status */
.pautaCard[data-status="novo"] .pautaStatus {
  background: rgba(40, 167, 69, 0.15);
  color: #28a745;
}
.pautaCard[data-status="aprovado"] .pautaStatus {
  background: rgba(40, 167, 69, 0.15);
  color: #28a745;
}
.pautaCard[data-status="rejeitado"] .pautaStatus {
  background: rgba(220, 53, 69, 0.15);
  color: #dc3545;
}
.pautaCard[data-status="aguardando votação"] .pautaStatus {
  background: rgba(0, 123, 255, 0.15);
  color: #007bff;
}
.pautaCard[data-status="encaminhado"] .pautaStatus {
  background: rgba(255, 193, 7, 0.15);
  color: #ffc107;
}

/* 🔹 Detalhes */
.pautaDetalhe {
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

/* 🔹 Botões */
.detailsBtn {
  background: #1d715b;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.detailsBtn:hover {
  background: #136549;
}

/* 🔹 Estilização do Grid de Sessões */
.sessoesGrid {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
gap: 20px;
margin-top: 20px;
}

/* 🔹 Estilização do Card de Sessão */
.sessaoCard {
background: #e9f7f4;
border-radius: 12px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
padding: 20px;
display: flex;
flex-direction: row;
transition: transform 0.2s ease, box-shadow 0.2s ease;
cursor: pointer;
border-left: 6px solid #1ca882;
flex-wrap: wrap;
justify-content: space-between;
align-content: center;
align-items: center;
}

/* 🔹 Efeito hover */
.sessaoCard:hover {
transform: translateY(-5px);
box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

/* 🔹 Cabeçalho do Card */
.sessaoInfo {
display: flex;
align-items: center;
gap: 12px;
font-size: 18px;
font-weight: bold;
}

/* Ícone de Informação */
.sessaoInfo .icon {
font-size: 22px;
color: #1d715b;
}

/* 🔹 Status da Sessão */
.sessaoStatus {
font-size: 14px;
font-weight: bold;
padding: 6px 12px;
border-radius: 15px;
display: inline-block;
text-align: center;
width: fit-content;
}

/* 🔹 Cores dinâmicas para os Status */
.sessaoCard[data-status="aberta"] {
border-left-color: #28a745;
}
.sessaoCard[data-status="agendada"] {
border-left-color: #ffc107;
}
.sessaoCard[data-status="em andamento"] {
border-left-color: #007bff;
}
.sessaoCard[data-status="encerrada"] {
border-left-color: #dc3545;
}

/* 🔹 Cores do Status */
.sessaoCard[data-status="aberta"] .sessaoStatus {
background: rgba(40, 167, 69, 0.15);
color: #28a745;
}
.sessaoCard[data-status="agendada"] .sessaoStatus {
background: rgba(255, 193, 7, 0.15);
color: #ffc107;
}
.sessaoCard[data-status="em andamento"] .sessaoStatus {
background: rgba(0, 123, 255, 0.15);
color: #007bff;
}
.sessaoCard[data-status="encerrada"] .sessaoStatus {
background: rgba(220, 53, 69, 0.15);
color: #dc3545;
}

/* 🔹 Informações da Sessão */
.sessaoNumero {
font-size: 16px;
color: #333;
display: flex;
align-items: center;
gap: 8px;
}

/* 🔹 Botão de Detalhes */
.detailsBtn {
background: #1d715b;
color: white;
font-size: 14px;
font-weight: bold;
padding: 8px 12px;
border: none;
border-radius: 8px;
cursor: pointer;
transition: background 0.2s ease;
display: flex;
align-items: center;
justify-content: center;
}

.detailsBtn:hover {
background: #136549;
}