/* 🔹 Estilos Gerais */
.painelSessaoContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.backgroundTitulo {
  padding: 10px;
  border: none;
  background-color: #e9f7f4;
}
  
  .listaVotos {
    list-style: none;
    font-size: 2.5rem;
    padding: 0;
  }
  
  .listaVotos li {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    background: transparent !important;
    border: none !important;
  }
  
  .listaVotos li:hover {
    background: transparent;
  }

  .resultadoVotacao {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
  }

  /* 🔹 Painel Principal - Lado Direito */
  .painelPrincipal {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    gap: 15px;
  }

  .painelPrincipal h2 {
    color: #fff;
    font-size: 1.7rem;
  }
  
  /* 🔹 Topo: Vereador, Cronômetro, Tempo de Fala */
  .topo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
  }
  
  .vereadorInfo {
    text-align: center;
  }
  
  .vereadorFoto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid #FFD700;
  }
  
  .vereadorInfo h2,
  .vereadorInfo p {
    margin: 5px 0;
  }
  
  .cronometro,
  .contagemRegressiva {
    text-align: center;
  }
  
  .cronometro h3,
  .contagemRegressiva h3 {
    color: #FFD700;
  }
  
  .tempoSessao,
  .tempoFala {
    font-size: 24px;
    font-weight: bold;
  }
  

  
  /* 🔹 Responsividade */
  @media (max-width: 1024px) {
    .painelSessaoContainer {
      flex-direction: column;
    }
  
    .quadroVotacao {
      width: 100%;
      padding: 10px;
    }

  }
  
  /* Estilização para a seção de Pauta em Discussão */
.discussao {      /* Fundo neutro */
  border: 1px solid #ccc;         /* Borda discreta */
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  width: 100% ;
}

.discussao h3 {
  color: #0a4b3a;
  margin-bottom: 12px;
  font-size: 2.5rem;
  border-bottom: 2px solid #ccc;
  padding-bottom: 8px;
}

.discussao p {
  color: #0a4b3a;
  margin-bottom: 12px;
  font-size: 1.6rem;
  padding-bottom: 8px;
  margin-top: 1px;
}

.discussao strong {
  color: #0a4b3a;
  margin-bottom: 12px;
  font-size: 2rem;
  padding-bottom: 8px;
}

  /* 🔹 Minuta */
  .minuta {
    font-size: 1.2rem;
  }

/* 🔹 Conteúdo Principal */
.dashboardContent {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  overflow: hidden; /* Evita rolagem lateral */
}

/* 🔹 Conteúdo Principal */
.dashboardContent h2 {
  font-size: 3rem;
color: white;
}

.headerSessao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1d715b;
  color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  border-radius: 5px;
}
.cronometroSessao {
  font-size: 7rem; /* 🔹 Destaca o tempo decorrido */
  font-weight: bold;
  margin-top: 10px;
}



/* Estilo do Quadro de Votação */
.quadroVotacao {
  background: black;
  color: white;
  padding: 20px;
  width: 70%;
  margin: 0;
  text-align: center;
}

.quadroVotacao h2 {
  font-size: 2rem;
  text-align: center;
  color: #FFD700;
}

/* Totais de Votos */
.totaisVotos {
  display: flex
  ;
      padding: 10px;
      margin-bottom: 15px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      text-align: center;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
    align-content: flex-end;
    align-items: flex-end;
}

.totaisVotos p {
  font-size: 18px;
  font-weight: bold;
}

/* Cores dos Totais */
.sim { color: #00ff00; font-weight: bold; } /* Verde */
.nao { color: #ff0000; font-weight: bold; } /* Vermelho */
.abstencao { color: #ffa500; font-weight: bold; } /* Laranja */

/* Lista de Votos */
.listaVotos {
  list-style: none;
  padding: 0;
}

.votoItem {
  font-size: 16px;
  padding: 8px;
  border-bottom: 1px solid #666;
}

/* Nome do Vereador */
.nome {
  font-weight: bold;
}

/* Resultado da Votação */
.resultadoVotacao {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
}

/* Estilização para a seção de Vereador em Fala */
.vereadorEmFala { 
  border: 2px solid #0a4b3a;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  background-color: #f5f5f5;
}

.vereadorEmFala h3 {
  color: #0a4b3a;
  margin-bottom: 16px;
  font-size: 2.5rem;
  border-bottom: 2px solid #ccc;
  padding-bottom: 10px;
}

/* Grid de Vereadores - aumenta a altura dos cartões */
.vereadorGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsivo */
  gap: 20px;
  text-align: center;
  color: #0a4b3a;
}

/* Cartão do Vereador */
.vereadorCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #333;
  padding: 30px;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  gap: 12px;
  text-align: center;
}

.vereadorCard:hover {
  background-color: #1ca882;
}

/* Foto do Vereador (Formato 3x4) */
.vereadorCard .fotoVereador {
  width: 250px;  /* Ajusta a largura */
  border-radius: 8px; /* Cantos levemente arredondados */
  object-fit: cover;
  border: 2px solid #fff;
  margin-bottom: 10px;
}

/* Nome do Vereador */
.vereadorCard p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #fff;
  margin-bottom: 8px;
}

/* Logo do Partido - agora abaixo da foto */
.fotoPartido {
  width: 60px;
  margin-top: -15px; /* Distância da foto do vereador */
}

/* Estilização do contador de tempo */
.timer {
  text-align: center;
  margin-top: 15px;
}

.timer p {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}

.timerDisplay {
  font-size: 50px;
  font-weight: bold;
  color: #ff4444; /* Destaque vermelho */
  background: black;
  padding: 12px 20px;
  border-radius: 8px;
  display: inline-block;
}

.painelConteudo {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  justify-content: center;
}

@media (max-width: 600px) {
.headerSessao {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 20px;
    background: #0a4b3a;
    color: white;
}

.painelPrincipal {
  flex: 1 1;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  padding: 20px;
  gap: 15px;
}
}

@media (max-width: 768px) {
  .painelPrincipal {
    flex: 1 1;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 20px;
    gap: 15px;
  }
  .vereadorEmFala {
    width: auto;
  }
  }

  .pautaGrupo {
    display: flex;
    align-items: center;
  }

  .pautaGrupo h2 {
    font-size: 2.0rem;
    border-bottom: none;
  }

  .pautaGrupo img {
    width: 12rem;
    max-height: 40px;
    padding-bottom: 5px;
    border-bottom: 2px solid #1d715b;
  }
  
.vereadorDestaque {
  background-color: #000;
  border: 4px solid #FFD700;
  border-radius: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
}

.vereadorDestaque img {
  max-width: 280px;
  border-radius: 12px;
  border: 3px solid white;
  margin-bottom: 20px;
}

.vereadorDestaque h2 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.vereadorDestaque .partidoImg {
  width: 80px;
  margin-top: 10px;
}

.vereadorDestaque .timerDisplay {
  font-size: 4rem;
  background: #222;
  border-radius: 12px;
  padding: 15px 30px;
  margin-top: 20px;
  border: 2px solid #ff4444;
  color: #ff4444;
}
