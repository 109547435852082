/* 🔹 Estrutura Principal */
.painelContainer {
    padding: 20px;
    background-color: #f8f9fa;
    height: 100vh;
  }
  
  .dashboardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    border-left: 5px solid #1ca882;
    padding: 20px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
  }
  
  /* 🔹 Títulos das Pautas */
  .dashboardHeader h3 {
      font-size: 1.9rem;
      color: #1d715b;
      font-weight: bold;
      margin-bottom: 0px !important;
      padding-left: 0px !important;
      border-left: 0px !important;
      margin-top: 0px !important;
  }  
  
  .dashboardHeader img {
    height: 50px;
  }
  
  /* 🔹 Área Principal */
  .contentGerenciar {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow-y: auto;
  }
  
  .usuariosContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;
  }
  
  .section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .tabelaContainer {
    width: 100%;
  }
  
  .acoes button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  /* 🔹 Pauta Grupo */
  .pautaGrupo {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
  }
  
  .pautaGrupo:hover {
    background: #e9f7f4;
  }
  
  .pautaGrupo h3 {
    margin-bottom: 10px;
    font-size: 18px;
    color: #1d715b;
  }
  
  .pautaGrupo ul {
    list-style: none;
    padding: 0;
  }
  
  .pautaGrupo li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .pautaGrupo li button {
    background: none;
    border: none;
    color: #1ca882;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
  }
  
  .pautaGrupo li svg {
    margin-right: 8px;
  }
  
  /* 🔹 Cards (visível apenas em telas menores) */
  .cardsContainer {
    display: none; /* Escondido por padrão */
  }
  
  @media screen and (min-width: 768px) {
    .tabelaContainer {
      display: block !important;
    }
  }
  
  
  /* 🔹 Media Query para telas menores */
  @media screen and (max-width: 767px) {
    .tabelaContainer {
      display: none; /* Esconder tabela em telas pequenas */
    }
  
    .cardsContainer {
      display: flex; /* Ativar cards */
      flex-wrap: wrap;
      gap: 16px;
      justify-content: center;
    }
  
    .filtrosContainer {
      display: grid;
          border-radius: 12px;
          color: #0a4b3a;
          flex-direction: row;
          flex-wrap: nowrap;
          gap: 15px;
          grid-template-columns: 1fr 1fr;
    }
  
    .pautaCard {
      background: #ffffff;
      border-radius: 10px;
      padding: 15px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
      width: 100%;
      max-width: 320px;
      transition: transform 0.2s ease, box-shadow 0.2s ease;
    }
  
    .pautaCard:hover {
      transform: translateY(-3px);
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    }
  
    .cardHeader {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
    }
  
    .cardIcon {
      font-size: 20px;
      color: #1d715b;
    }
  
    .cardBody p {
      margin: 5px 0;
      font-size: 14px;
    }
  
    .status {
      color: #1ca882;
      font-weight: bold;
    }
  
    .cardActions {
      display: flex;
      justify-content: space-around;
      margin-top: 10px;
    }
  
    .actionButton {
      background: none;
      border: none;
      color: #1ca882;
      font-size: 16px;
      cursor: pointer;
      transition: color 0.3s ease;
    }
  
    .actionButton:hover {
      color: #157a64;
    }
  
    .semPautas {
      text-align: center;
      font-size: 14px;
      color: #666;
    }
  }
  
  /* 🔹 Fundo escurecido e desfocado */
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    backdrop-filter: blur(6px);
    animation: fadeIn 0.3s ease-in-out;
    padding: 20px;
    overflow-y: auto;
  }
  
  /* 🔹 Estilização do Modal */
  .modal {
    background: #fff;
    width: 100%;
    max-width: 720px;
    padding: 35px;
    border-radius: 12px;
    box-shadow: 0px 12px 35px rgba(0, 0, 0, 0.3);
    animation: slideDown 0.3s ease-in-out;
    position: relative;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  /* 🔹 Animações */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    from {
      transform: translateY(-30px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  /* 🔹 Título do Modal */
  .modalTitle {
    display: flex;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
    color: #1d715b;
    margin-bottom: 25px;
    border-bottom: 3px solid #1d715b;
    padding-bottom: 12px;
  }
  
  .modalTitle svg {
    margin-right: 12px;
    color: #1ca882;
  }
  
  /* 🔹 Botão de Fechar */
  .modalClose {
    position: absolute;
    top: 12px;
    right: 15px;
    background: none;
    border: none;
    font-size: 22px;
    cursor: pointer;
    color: #666;
    transition: color 0.3s ease-in-out;
  }
  
  .modalClose:hover {
    color: #1ca882;
  }
  
  /* 🔹 Layout das linhas */
  .row {
    display: flex;
    justify-content: space-between;
    gap: 18px;
    flex-wrap: wrap;
  }
  
  .row .inputGroup {
    flex: 1;
    min-width: 250px;
  }
  
  /* 🔹 Duas colunas para campos menores */
  .row-two {
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .row-two .inputGroup {
    flex: 1;
    min-width: 150px;
  }
  
  /* 🔹 Agrupamento dos Inputs */
  .inputGroup {
    display: flex;
    flex-direction: column;
  }
  
  /* 🔹 Labels */
  label {
    font-size: 1.2rem;
    font-weight: bold;
    color: #11503f;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }
  
  /* 🔹 Ícones nos Labels */
  label svg {
    margin-right: 10px;
    color: #1d715b;
  }
  
  /* 🔹 Títulos das Seções */
  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #1d715b;
    margin-bottom: 20px;
    padding-bottom: 5px;
    border-bottom: 2px solid #1d715b;
  }
  
  /* 🔹 Títulos das Pautas */
  h3 {
    font-size: 1.2rem;
    color: #1d715b;
    font-weight: bold;
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 4px solid #1ca882;
  }
  
  /* 🔹 Responsividade */
  @media (max-width: 768px) {
    .modal {
      width: 95%;
      max-width: 420px;
      padding: 22px;
    }
    
    .row {
      flex-direction: column;
    }
  
    .row-two {
      flex-direction: column;
    }
  
    .painelContainer {
      margin-left: 0;
    }
  
    .filtrosContainer {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  .acoes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }
  
  /* 🔹 Estilização da Tabela */
  .pautaTabela {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease;
  }
  
  .pautaTabela th, .pautaTabela td {
    padding: 12px 15px;
    text-align: left;
  }
  
  .pautaTabela th {
    font-size: 16px;
    font-weight: bold;
    color: #FFF;
    background-color: #1d715b;
  }
  
  .pautaTabela td {
    vertical-align: middle;
    font-size: 14px;
  }
  
  tr {
    color: #11503f;
    transition: transform 0.2s;
  }
  
  tr:hover {
  background-color: #e9f7f4;
  transform: scale(1.02);
  }
  
  /* 🔹 Manter ícones alinhados ao texto */
  .pautaTabela td svg {
    margin-right: 8px;
    color: #1ca882;
  }
  
  /* 🔹 Alinhamento das Ações */
  .acoes {
    display: flex;
    gap: 8px;
  }
  
  .acoes button {
    background: none;
    border: none;
    color: #1ca882;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: color 0.3s ease;
  }
  
  /* Container dos Filtros */
  .filtrosContainer {
    display: grid;
        border-radius: 12px;
        color: #0a4b3a;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 15px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  
  /* Cards do Filtro */
  .filtroCard {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 8px 12px;
    border-radius: 8px;
    transition: background-color 0.2s ease, transform 0.2s ease;
    background: #e9f7f4;
    border: 2px solid transparent;
  }
  
  .filtroCard:hover {
    transform: scale(1.01);
  }
  
  /* Ícones do Filtro */
  .filtroIcon {
    font-size: 1.5rem;
  }
  
  /* Estilizando cards ativos */
  .filtroCard.ativo {
    border: 2px solid #0d6efd;
    background: rgba(13, 110, 253, 0.1);
  }
  
  .bottomButtons {
    display: flex;
    flex-direction: column;
    margin-top: auto; /* Move os botões para a base */
  }
  