/* Garantindo que root e body ocupem 100% da tela */
#root {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  padding-bottom: 70px;
}
